@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
}

/** Background **/

/* Header */
.large-header {
  position: relative;
  width: 100%;
  /* background: #333; */
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
  height: auto !important;
  background-size: cover;
  background-position: center center;
  z-index: 0;
}

#large-header {
  /* background-image: url("https://www.marcoguglie.it/Codepen/AnimatedHeaderBg/demo-1/img/demo-1-bg.jpg"); */
}

.content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
}

/** Background **/
